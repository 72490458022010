import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';

import ReceiptUserItem from './ReceiptUserItem';

const useStyles = makeStyles(theme => ({
  total: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    lineHeight: '1.7',
  },
}));

export default function ReceiptUserItems({ items, user, userTotals }) {
  const classes = useStyles();

  return (
    <div>
      {items.map(item => {
        if (item.users.includes(user.id)) {
          return <ReceiptUserItem user={user} item={item} key={item.id} />
        }
      })}
      <Box mt={2}>
        <Grid container>
          <Grid item xs={6}>
            <Typography>Tax:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">${userTotals.tax}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Tip:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">${userTotals.tip}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.total}>Total:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align="right" className={classes.total}>${userTotals.total}</Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
