import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { ItemsConsumer } from '../Provider';

const useStyles = makeStyles(theme => ({
  removeButton: {
    float: 'right',
  },
}));

export default function ReceiptUserItem({ user, item }) {
  const classes = useStyles();

  const getPrice = () => {
    return Math.round(item.price / item.users.length * 100) / 100;
  }

  const handleRemove = () => {
    console.log('removing...')
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={6}>
        <Typography>{item.item}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography align="right">${getPrice()}</Typography>
      </Grid>
      <Grid item xs={3}>
        <ItemsConsumer>
          {({ removeUserFromItem }) => (
            <IconButton onClick={() => removeUserFromItem(item, user)} className={classes.removeButton}>
              <DeleteIcon />
            </IconButton>
          )}
        </ItemsConsumer>
      </Grid>
    </Grid>
  )
}
