import axios from 'axios'

export async function create(imageBlob, title) {
  try {
    const response = await axios.post('https://api.divee.io', {
      params: {
        title: title,
      },
      data: imageBlob,
    })
    console.log(response)
    return response.data
  } catch (error) {
    console.log(error)
  }
}
