import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper, Typography } from '@material-ui/core';

import { TotalConsumer } from './ReceiptProvider';
import ReceiptTax from './ReceiptTax';
import ReceiptTip from './ReceiptTip';

const useStyles = makeStyles(theme => ({
  totalPaper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
  },
  totalPrice: {
    color: '#666666',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const totalObject = {
  'Subtotal': '$80',
  'Tax': '$10',
  'Tip': '$10',
}
// const total = '$100'

export default function ReceiptTotals() {
  const classes = useStyles();

  return (
    <TotalConsumer>
      {({ subtotal, tax, editTax, tip, tipObject, editTip, total }) => (
        <Box m={4}>
          <Paper className={classes.totalPaper}>
            <Box py={4} px={6}>
              <Grid container justify="center">
                <Grid item xs={8}>
                  <Typography variant="h6">Subtotal:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" align="right">${subtotal}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ReceiptTax tax={tax} onEditTax={editTax} />
                </Grid>
                <Grid item xs={12}>
                  <ReceiptTip tip={tip} tipObject={tipObject} onEditTip={editTip} />
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h6" className={classes.bold}>Total:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" className={classes.bold} align="right">${total}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      )}
    </TotalConsumer>
  )
}
