import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Box, Collapse,
  Typography, ButtonGroup, Button, TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tipCollapse: {
    width: '100%',
  },
  tipInput: {
    marginLeft: theme.spacing(4),
    width: '80px',
  },
  buttonLeft: {
    borderBottomLeftRadius: 0,
  },
  buttonRight: {
    borderBottomRightRadius: 0,
  },
  buttonCustom: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 'none',
  },
  noBoxShadow: {
    boxShadow: 'none !important',
  },
}));

export default function ReceiptTip({ tip, tipObject, onEditTip }) {
  const classes = useStyles();

  const [editTip, setEditTip] = React.useState(false);
  const [selectedTip, setSelectedTip] = React.useState(tipObject.selected || 0);
  const [selectedType, setSelectedType] = React.useState(tipObject.type || 'percent');
  const [customTip, setCustomTip] = React.useState(tipObject.value || 0);

  const handleSelectedTip = (id, value, type) => {
    onEditTip({
      value: value || customTip || 0,
      type: type || selectedType,
      selected: id,
    });
    setSelectedTip(id);
  }

  const handleSelectedType = type => {
    onEditTip({
      value: customTip || 0,
      type: type,
      selected: selectedTip,
    });
    setSelectedType(type);
  }

  const handleTipChange = () => event => {
    onEditTip({
      value: event.target.value || 0,
      type: selectedType,
      selected: selectedTip,
    });
    setCustomTip(event.target.value);
  };

  return (
    <Grid container justify="center">
      <Grid item xs={2}>
        <Typography variant="h6">Tip:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Button color="primary" onClick={() => setEditTip(!editTip)}>
          {editTip ? 'Done' : 'Edit'}
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6" align="right">${tip}</Typography>
      </Grid>
      <Collapse in={editTip} timeout="auto" className={classes.tipCollapse} unmountOnExit>
        <Box my={3}>
          <Grid container>
            <Grid item xs={12}>
              <ButtonGroup color="primary" fullWidth>
                <Button
                  variant={selectedTip === 1 ? 'contained' : ''}
                  onClick={() => handleSelectedTip(1, 15, 'percent')}
                  className={`${classes.buttonLeft} ${classes.noBoxShadow}`}
                >
                  15%
                </Button>
                <Button
                  variant={selectedTip === 2 ? 'contained' : ''}
                  onClick={() => handleSelectedTip(2, 20, 'percent')}
                  className={classes.noBoxShadow}
                >
                  20%
                </Button>
                <Button
                  variant={selectedTip === 3 ? 'contained' : ''}
                  onClick={() => handleSelectedTip(3, 25, 'percent')}
                  className={`${classes.buttonRight} ${classes.noBoxShadow}`}
                >
                  25%
                </Button>
              </ButtonGroup>
              <Button
                color="primary"
                variant={selectedTip === 4 ? 'contained' : 'outlined'}
                fullWidth
                onClick={() => handleSelectedTip(4)}
                className={`${classes.buttonCustom} ${classes.noBoxShadow}`}
              >
                Custom
              </Button>
            </Grid>
            <Grid item xs={9}>
              <Collapse in={selectedTip === 4} timeout="auto" unmountOnExit>
                <Box mt={3}>
                  <ButtonGroup color="primary">
                    <Button
                      variant={selectedType === 'percent' ? 'contained' : ''}
                      onClick={() => handleSelectedType('percent')}
                      className={classes.noBoxShadow}
                    >
                      %
                    </Button>
                    <Button
                      variant={selectedType === 'dollars' ? 'contained' : ''}
                      onClick={() => handleSelectedType('dollars')}
                      className={classes.noBoxShadow}
                    >
                      $
                    </Button>
                  </ButtonGroup>
                  <TextField
                    id="custom-tip"
                    type="number"
                    placeholder="0.00"
                    className={classes.tipInput}
                    value={customTip}
                    onChange={handleTipChange('customTip')}
                  />
                </Box>
              </Collapse>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Grid>
  )
}
