import React from 'react'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, TextField } from '@material-ui/core'

import { ReceiptConsumer } from '../Provider'
import Navigation from '../Navigation'

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
  },
  nameInput: {
    backgroundColor: 'rgba(34, 148, 230, 0.1)',
    borderRadius: '16px',
  }
}))

export default function PageImage() {
  const classes = useStyles()

  const [values, setValues] = React.useState({
    name: '',
  })

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  }

  const handleSubmit = createReceipt => {
    createReceipt(values.name)
  }

  return (
    <ReceiptConsumer>
      {({ image, compressedImage, createReceipt }) => (compressedImage.src || image.src ?
        <Navigation back="/" next={() => handleSubmit(createReceipt)}>
          <Grid container direction="column" alignItems="center">
            <Grid item style={{ width: '100%' }}>
              <Box px={12}>
                <img src={compressedImage.src || image.src} className={classes.image} />
              </Box>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Box mt={8} px={12}>
                <TextField
                  id="restaurant-name"
                  label="Restaurant Name"
                  name="restaurant-name"
                  value={values.name}
                  onChange={handleChange('name')}
                  variant="outlined"
                  InputProps={{
                    className: classes.nameInput,
                  }}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
        </Navigation>
        :
        <Redirect to="/" />
      )}
    </ReceiptConsumer>
  )
}
