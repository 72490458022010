import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, TextField, InputAdornment } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

}));

export default function ReceiptTax({ tax, onEditTax }) {
  const classes = useStyles();

  const [tempTax, setTempTax] = React.useState(tax);

  const handleTax = event => {
    setTempTax(event.target.value);
  }

  const [editTax, setEditTax] = React.useState(false);

  const handleEditTax = () => {
    if (editTax) {
      onEditTax(tempTax || 0);
    }
    setEditTax(!editTax);
  }

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography variant="h6">Tax:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Button color="primary" onClick={() => handleEditTax()}>
          {editTax ? 'Done' : 'Edit'}
        </Button>
      </Grid>
      <Grid item xs={4}>
        {!editTax ?
          <Typography variant="h6" align="right">{`$${tax}`}</Typography>
          :
          <TextField
            id="custom-tax"
            type="number"
            placeholder="0.00"
            value={tempTax}
            onChange={handleTax}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
          />
        }
      </Grid>
    </Grid>
  )
}
