import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
// import RestaurantIcon from '@material-ui/icons/Restaurant';
// import PhotoIcon from '@material-ui/icons/Photo';

import DrawerLine from './DrawerLine';

const useStyles = makeStyles(theme => ({
  noPointer: {
    pointerEvents: 'none',
  },
  handleWrapper: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0 0 20px 20px',
    color: theme.palette.primary.contrastText,
  },
  handleWrapperBoxShadow: {
    // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)',
    boxShadow: '0px -1px 3px 2px rgba(0,0,0,0.2)',
  },
  handleContent: {
    marginBottom: theme.spacing(1),
  },
  title: {
    fontWeight: 'bold',
  },
}));

export default function ReceiptUpperHandle({name, noBoxShadow}) {
  const classes = useStyles();

  return (
    <Box pt={4} pb={2} px={8} className={(noBoxShadow ? '' : `${classes.handleWrapperBoxShadow}`) + ` ${classes.handleWrapper} ${classes.noPointer}`}>
      <Grid container className={classes.handleContent}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>{name}</Typography>
        </Grid>
      </Grid>
      <DrawerLine />
    </Box>
  )
}
