import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {  } from '@material-ui/core';

import ReceiptLowerHandle from './ReceiptLowerHandle';
import ReceiptUsers from './ReceiptUsers';
import ReceiptTotals from './ReceiptTotals';

const useStyles = makeStyles(theme => ({

}));

export default function ReceiptLowerDrawer() {
  const classes = useStyles();

  return (
    <div>
      <ReceiptLowerHandle noBoxShadow={true} />
      <ReceiptUsers />
      <ReceiptTotals />
    </div>
  )
}
