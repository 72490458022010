import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'

import Theme from './utils/theme'
import { Provider } from './components/Provider'
import Routing from './Routing'

import './App.css'

export default function App() {
  return (
    <ThemeProvider theme={Theme}>
      <Provider>
        <Router>
          <Routing />
        </Router>
      </Provider>
    </ThemeProvider>
  )
}
