import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import Clipboard from './Clipboard'
import HomeReceipt from './HomeReceipt'

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    width: '100%',
  },
}))

export default function PageHome() {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Clipboard>
        <HomeReceipt />
      </Clipboard>
    </div>
  )
}
