import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, TextField } from '@material-ui/core'

import Navigation from '../Navigation'

const useStyles = makeStyles(theme => ({

}))

export default function PageTaxTip() {
  const classes = useStyles()

  const [values, setValues] = React.useState({
    tax: 0,
    tip: 0,
  })

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  }

  return (
    <Navigation back="/test" next="/">
      <Box>
        <TextField
          id="tax"
          label="Tax"
          name="tax"
          value={values.tax}
          onChange={handleChange('tax')}
          fullWidth
        />
        <TextField
          id="tip"
          label="Tip"
          name="tip"
          value={values.tip}
          onChange={handleChange('tip')}
          fullWidth
        />
      </Box>
    </Navigation>
  )
}
