import React from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'

import Header from './components/Header'
import PageHome from './components/home/PageHome'
import PageImage from './components/home/PageImage'
import PageTaxTip from './components/receipt/PageTaxTip'
import Receipt from './components/receipt/Receipt'
import Nav from './components/Nav'

export default function Routing() {
  // const location = useLocation()
  // const transitions = useTransition(location, location => location.pathname, {
  //   from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
  //   // ...(location === '/' && { from: { opacity: 0, transform: 'translate3d(100%,0,0)' } }),
  //   enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  //   leave: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
  // })
  //
  // return transitions.map(({ item: location, props, key }) => (
  //   <animated.div key={key} style={props} className="App">
  //     {/* <Header /> */}
  //     <Switch location={location}>
  //       <Route path="/" exact component={Home} />
  //       <Route path="/:id" component={A} />
  //     </Switch>
  //     {/* <Nav /> */}
  //   </animated.div>
  // ))

  return (
    <div className="App">
      {/* <Header /> */}
      <Switch>
        <Route path="/" exact component={PageHome} />
        <Route path="/image" exact component={PageImage} />
        <Route path="/taxtip" exact component={PageTaxTip} />
        <Route path="/:id" component={Receipt} />
      </Switch>
      {/* <Nav /> */}
    </div>
  )
}
