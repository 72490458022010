import React from 'react'
import imageCompression from 'browser-image-compression'

import { create } from '../api/receipt'

const ReceiptContext = React.createContext()
const ItemsContext = React.createContext()
const UsersContext = React.createContext()
const TotalContext = React.createContext()

export function Provider(props) {
  const [receiptData, setReceiptData] = React.useState({
    name: 'Testaurant',
    date: '2069-04-20',
    location: 'Seattle',
    imageSrc: 'https://s3-us-west-2.amazonaws.com/img.divee.io/ftjm6tm3%2Btest2.jpg',
  })
  const [image, setImage] = React.useState({
    src: '',
    blob: '',
  })
  const [compressedImage, setCompressedImage] = React.useState({
    src: '',
    blob: '',
  })

  const updateImage = async (imageFile) => {
    setImage({
      src: URL.createObjectURL(imageFile),
      blob: imageFile,
    })

    try {
      const compressedFile = await imageCompression(imageFile, { maxSizeMB: 1 })

      setCompressedImage({
        src: URL.createObjectURL(compressedFile),
        blob: compressedFile,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const createReceipt = title => {
    const response = create(compressedImage, title)
  }

  const [nextItem, setNextItem] = React.useState(5)
  const [items, setItems] = React.useState([
    {
      id: 1,
      item: 'Chicken',
      price: 10,
      users: [1, 2],
    },
    {
      id: 2,
      item: 'Steak',
      price: 20,
      users: [3, 4],
    },
    {
      id: 3,
      item: 'Garlic Bread',
      price: 5,
      users: [5],
    },
    {
      id: 4,
      item: 'Beer',
      price: 10,
      users: [],
    },
  ])

  const addItem = item => {
    setItems(
      [...items, {
        id: nextItem,
        item: item.item,
        price: parseFloat(item.price),
        users: [],
      }]
    );
    setNextItem(nextItem + 1);
  }

  const editItem = updatedItem => {
    setItems(
      items.map(item => {
        return item.id === updatedItem.id ? updatedItem : item
      })
    )
  }

  const removeItem = deletedItem => {
    setItems(
      items.filter(item => item.id !== deletedItem.id)
    )
  }

  const removeUserFromItems = deletedUser => {
    setItems(
      items.map(item => {
        return {
          ...item,
          users: item.users.filter(id => id !== deletedUser.id)
        }
      })
    )
  }

  const removeUserFromItem = (item, user) => {
    setItems(
      items.map(i => {
        return i.id !== item.id ? i : {
          ...i,
          users: i.users.filter(id => id !== user.id)
        }
      })
    )
  }

  const [nextUser, setNextUser] = React.useState(6)
  const [users, setUsers] = React.useState([
    {
      id: 1,
      name: 'Bryan',
      total: '$40',
      color: 'red',
    },
    {
      id: 2,
      name: 'Tyler',
      total: '$60',
      color: 'purple',
    },
    {
      id: 3,
      name: 'Colin',
      total: '$110',
      color: 'blue',
    },
    {
      id: 4,
      name: 'Will',
      total: '$40',
      color: 'green',
    },
    {
      id: 5,
      name: 'Pauline',
      total: '$60',
      color: 'yellow',
    },
  ])

  const addUser = user => {
    setUsers(
      [...users, {
        ...user,
        id: nextUser,
      }]
    )
    setNextUser(nextUser + 1)
    return nextUser;
  }

  const editUser = updatedUser => {
    setUsers(
      users.map(user => {
        return user.id === updatedUser.id ? updatedUser : user
      })
    )
  }

  const removeUser = deletedUser => {
    setUsers(
      users.filter(user => user.id !== deletedUser.id)
    )
  }

  return (
    <ReceiptContext.Provider
      value={{
        data: receiptData,
        image: image,
        compressedImage: compressedImage,
        updateImage: updateImage,
        createReceipt: createReceipt,
      }}
    >
      <ItemsContext.Provider
        value={{
          items: items,
          addItem: addItem,
          editItem: editItem,
          removeItem: removeItem,
          removeUserFromItems: removeUserFromItems,
          removeUserFromItem: removeUserFromItem,
        }}
      >
        <UsersContext.Provider
          value={{
            users: users,
            addUser: addUser,
            editUser: editUser,
            removeUser: removeUser,
          }}
        >
          <TotalProvider items={items} users={users}>
            {props.children}
          </TotalProvider>
        </UsersContext.Provider>
      </ItemsContext.Provider>
    </ReceiptContext.Provider>
  )
}

function TotalProvider({children, items, users}) {
  const subtotal = () => {
    return items.reduce((acc, item) => acc + item.price, 0)
  }

  const [tax, setTax] = React.useState(10);

  const editTax = newTax => {
    setTax(parseFloat(newTax))
  }

  const [tip, setTip] = React.useState({
    type: 'percent',
    value: 0,
  })

  const calcTip = () => {
    return tip.type === 'percent' ? Math.round(subtotal() * tip.value) / 100 : tip.value
  }

  const editTip = newTip => {
    setTip({
      ...newTip,
      value: parseFloat(newTip.value)
    })
  }

  const total = () => {
    return subtotal() + tax + calcTip()
  }

  const userTotals = user => {
    let userSubtotal = items.reduce((acc, item) => {
      return acc + (item.users.includes(user.id) ? Math.round(item.price / item.users.length * 100) / 100 : 0)
    }, 0)
    let userRatio = userSubtotal / subtotal()
    let userTax = Math.round(tax * userRatio * 100) / 100
    let userTip = Math.round(calcTip() * userRatio * 100) / 100
    return {
      tax: userTax,
      tip: userTip,
      total: userSubtotal + userTax + userTip,
    }
  }

  return (
    <TotalContext.Provider
      value={{
        subtotal: subtotal(),
        tax: tax,
        editTax: editTax,
        tip: calcTip(),
        tipObject: tip,
        editTip: editTip,
        total: total(),
        userTotals: userTotals,
      }}
    >
      {children}
    </TotalContext.Provider>
  )
}

export const ReceiptConsumer = ReceiptContext.Consumer
export const ItemsConsumer = ItemsContext.Consumer
export const UsersConsumer = UsersContext.Consumer
export const TotalConsumer = TotalContext.Consumer
