import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';

import { ItemsConsumer } from '../Provider';
import ReceiptItem from './ReceiptItem';
import ReceiptAddItem from './ReceiptAddItem';
import ReceiptUsers from './ReceiptUsers';

const useStyles = makeStyles(theme => ({

}));

export default function ReceiptItems() {
  const classes = useStyles();

  return (
    <Grid container>
      <ItemsConsumer>
        {({ items, editItem, removeItem }) => (
          items.map(item => {
            return <ReceiptItem item={item} onEditItem={editItem} onDelete={removeItem} key={item.id} />
          })
        )}
      </ItemsConsumer>
    </Grid>
  )
}
