import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
// import { TextField, InputAdornment, IconButton } from '@material-ui/core';
// import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  input: {
    fontSize: '1.3rem',
    '& > fieldset': {
      // borderColor: 'transparent',
      // borderBottomColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
}));

export default function ReceiptInfo({ id, label, name, autoComplete, value, type }) {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    name: value,
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const focusInputField = React.useRef(null);

  return (
    <TextField
      id={id}
      label={label}
      name={name}
      autoComplete={autoComplete}
      value={values.name}
      type={type}
      onChange={handleChange('name')}
      fullWidth
      // variant="outlined"
      inputRef={focusInputField}
      InputProps={{
        className: classes.input,
        // endAdornment: (
        //   <InputAdornment position="end">
        //     <IconButton onClick={() => {
        //         setTimeout(() => {
        //           focusInputField.current.focus();
        //         }, 100);
        //       }}
        //     >
        //       <EditIcon />
        //     </IconButton>
        //   </InputAdornment>
        // ),
      }}
    />
  )
}
