import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper, Avatar, Button } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  colorsWrapper: {
    // margin: '0 24px 0 0 !important',
    // position: 'absolute',
    boxShadow: '0 0 0 2000px rgba(0,0,0,0.5)',
    left: 0,
    margin: '0 24px !important',
    position: 'fixed',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
}));

export default function ReceiptColorPicker({ selectedColor, onSelect, onClose }) {
  const classes = useStyles();

  return (
    <Paper className={classes.colorsWrapper}>
      <Grid container>
        {Array.from(Object.keys(colors), (color, index) => {
          if (index !== 0) {
            return <Grid item xs={2} key={index}>
              <Box m={2}>
                <Avatar onClick={() => onSelect(color)} style={{
                  backgroundColor: colors[color][500],
                  boxShadow: selectedColor === color ? `inset 0 0 0 3px ${colors[color][900]}` : 'none',
                }} />
              </Box>
            </Grid>
          }
        })}
        <Grid item xs={6} />
        <Grid item xs={4}>
          <Box m={3}>
            <Button variant="contained" color="primary" fullWidth onClick={onClose}>Close</Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
