import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper, Typography } from '@material-ui/core';

import { ItemsConsumer, UsersConsumer } from '../Provider';
import ReceiptUser from './ReceiptUser';
import ReceiptUserNew from './ReceiptUserNew';

const useStyles = makeStyles(theme => ({
  usersBox: {
    // overflowX: 'scroll',
    // '&::-webkit-scrollbar': {
    //   width: '0 !important',
    // },
  },
  usersScroll: {
    // backgroundColor: '#FFFFFF',
    // borderRadius: '20px',
    display: 'inline-flex',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0 !important',
    },
  },
}));

export default function ReceiptUsers() {
  const classes = useStyles();

  return (
    <div className={classes.usersScroll}>
      <ItemsConsumer>
        {({ items, editItem, removeUserFromItems }) => (
          <UsersConsumer>
            {({ users, editUser, removeUser }) => (
              users.map((user, index) => {
                return <ReceiptUser
                  items={items}
                  onEditItem={editItem}
                  onRemoveUserFromItems={removeUserFromItems}
                  user={user}
                  onEditUser={editUser}
                  onRemoveUser={removeUser}
                  key={index}
                />
              })
            )}
          </UsersConsumer>
        )}
      </ItemsConsumer>
      <ReceiptUserNew />
    </div>
    // <Box px={8} className={classes.usersBox}>
    //
    // </Box>
  )
}
