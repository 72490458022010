import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper, Typography } from '@material-ui/core';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '../Constants';

export default function ReceiptItem({ item, onEditItem, onDelete }) {
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.ITEM },
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
  })

  const useStyles = makeStyles(theme => ({
    itemContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    itemPaper: {
      borderRadius: '16px',
      boxShadow: '0 3px 5px 1px rgba(0,0,0,0.1)',
      width: '100%',
      height: '60px',
    },
    itemText: {
      fontWeight: 'bold',
    },
    itemPrice: {
      color: '#777777',
    },
  }));

  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.itemContainer}>
      <Paper ref={drag} className={classes.itemPaper}>
        <Box py={4} px={8}>
          <Grid container>
            <Grid item xs={1}>
              <Typography className={classes.itemText}>1</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography className={classes.itemText}>X</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.itemText}>{item.item}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography align="right" className={classes.itemPrice}>${item.price}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  )
}
