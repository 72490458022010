import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { ItemsConsumer } from './ReceiptProvider';
import ReceiptAddItemCard from './ReceiptAddItemCard';

const useStyles = makeStyles(theme => ({
  addItem: {
    bottom: '76px',
    position: 'fixed',
    right: '16px',
  },
  addItemIcon: {
    marginRight: theme.spacing(1)
  },
}));

export default function ReceiptAddItem() {
  const classes = useStyles();

  const [addOpen, setAddOpen] = React.useState(false);

  return (
    <div className={classes.addItem} style={addOpen ? {left: '16px'} : {}}>
      {addOpen ?
        <ItemsConsumer>
          {({ addItem }) => (
            <ReceiptAddItemCard onClose={() => setAddOpen(false)} onSave={addItem} />
          )}
        </ItemsConsumer>
        :
        <Fab variant="extended" color="primary" aria-label="add item" onClick={() => setAddOpen(true)}>
          <AddIcon className={classes.addItemIcon} />
          Add Item
        </Fab>
      }
    </div>
  )
}
