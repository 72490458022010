import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, IconButton, Button } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  backButton: {
    borderRadius: '50%',
    minWidth: 'unset',
    padding: '12px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  continueButton: {
    borderRadius: '40px',
    float: 'right',
    fontSize: '1.3rem',
    '& .MuiButton-endIcon svg': {
      fontSize: '2.1875rem',
    },
  },
}))

export default function Navigation({ children, back, next }) {
  const classes = useStyles()

  const nextProps = typeof(next) === 'string' ? { to: next } : { onClick: next }

  return (
    <div className={classes.wrapper}>
      <Box m={4}>
        <Link to={back}>
          <Button
            aria-label="back"
            component="span"
            className={classes.backButton}
          >
            <ChevronLeftIcon fontSize="large" />
          </Button>
        </Link>
      </Box>
      <div className={classes.content}>
        {children}
      </div>
      <Box mx={4} mb={4}>
        <Link {...nextProps}>
          <Button
            size="large"
            component="span"
            endIcon={<ChevronRightIcon />}
            className={classes.continueButton}
          >
            Continue
          </Button>
        </Link>
      </Box>
    </div>
  )
}
