import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  drawerLine: {
    border: `2px solid ${theme.palette.primary.contrastText}`,
    borderRadius: '2px',
    margin: 'auto',
    width: '60px',
  },
}));

export default function DrawerLine() {
  const classes = useStyles();
  return (
    <div className={classes.drawerLine} />
  )
}
