import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import EditField from './EditField';
import ReceiptUpperHandle from './ReceiptUpperHandle';

const useStyles = makeStyles(theme => ({
  upperDrawerWrapper: {
    borderRadius: '0 0 20px 20px',
  },
  linksWrapper: {
    position: 'absolute',
    transition: '.3s ease',
  },
  linksHidden: {
    transform: 'translate(-150px, 0)'
  },
  imageWrapper: {
    backgroundColor: '#FFFFFF',
    // border: '1px solid #888888',
    borderRadius: '6px',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    height: '80px',
    float: 'right',
    transition: '.3s ease',
    width: '80px',
  },
  imageExpand: {
    borderRadius: '20px',
    height: '400px',
    width: '100%',
  },
  image: {
    display: 'block',
    height: '100%',
    margin: 'auto',
  },
  imageClose: {
    backgroundColor: 'rgb(0,0,0,0.25)',
    color: '#FFFFFF',
    position: 'absolute',
    right: '24px',
    top: '32px',
  },
  inputPaper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
  },
  inputWrapper: {
    marginTop: theme.spacing(4),
  },
  inputBorder: {
    '& > fieldset': {
      borderColor: 'transparent',
    },
  },
}));

export default function ReceiptUpperDrawer({ data }) {
  const classes = useStyles();

  const [values, setValues] = React.useState(data);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const [imageExpand, setImageExpand] = React.useState(false)

  return (
    <div className={classes.upperDrawerWrapper}>
      <Box pt={6} pb={4} mx={4}>
        <Grid container>
          {/* <Box className={`${classes.linksWrapper} ` + (imageExpand ? classes.linksHidden : '')}>
            <Typography>Back to Home</Typography>
            <Typography>Settings</Typography>
          </Box> */}
          <Grid item xs={12} align="right">
            <Box mb={4} className={`${classes.imageWrapper} ` + (imageExpand ? classes.imageExpand : '')} onClick={!imageExpand ? () => setImageExpand(true) : undefined}>
              {imageExpand ?
                <IconButton className={classes.imageClose} onClick={() => setImageExpand(false)}>
                  <CloseIcon />
                </IconButton>
                : ''
              }
              <img className={classes.image} src={data.imageSrc} alt="receipt" />
            </Box>
          </Grid>
        </Grid>
        <Paper className={classes.inputPaper}>
          <Box pt={4} pb={8} px={8}>
            <Grid container justify="center">
              <Grid item xs={12} className={classes.inputWrapper}>
                <EditField
                  id="name-input"
                  label="Restaurant Name"
                  name="restaurant-name"
                  autoComplete="restaurant-name"
                  value={values.name}
                />
              </Grid>
              <Grid item xs={12} className={classes.inputWrapper}>
                <EditField
                  id="date-input"
                  label="Date"
                  name="date"
                  autoComplete="date"
                  type="date"
                  value={values.date}
                />
              </Grid>
              <Grid item xs={12} className={classes.inputWrapper}>
                <EditField
                  id="location-input"
                  label="Location"
                  name="location"
                  autoComplete="location"
                  value={values.location}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
      <ReceiptUpperHandle name={values.name} noBoxShadow={true} />
    </div>
  )
}
