import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  typography: {
    fontFamily: ['Rubik, sans-serif'],
  },
  palette: {
    primary: {
      // light: blue[400],
      main: '#2294E6',
      // dark: '#07203A',
      contrastText: '#fff',
    },
    // secondary: {
    //   // light: blue[400],
    //   main: '#FFFFFF',
    //   dark: '#EEEEEE',
    //   contrastText: '#2294E6',
    // },
  },
  spacing: 4,
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '16px',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '16px',
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: '#2294E6',
        color: '#FFFFFF',
      },
    },
  },
});
