import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';

import { UsersConsumer, TotalConsumer } from './ReceiptProvider';
import DrawerLine from './DrawerLine';

const useStyles = makeStyles(theme => ({
  noPointer: {
    pointerEvents: 'none',
  },
  handleWrapper: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '20px 20px 0 0',
    color: theme.palette.primary.contrastText,
    paddingBottom: theme.spacing(2),
  },
  handleWrapperBoxShadow: {
    // boxShadow: '0px -2px 4px -1px rgba(0,0,0,0.2), 0px -2px 4px 0px rgba(0,0,0,0.14), 0px -1px 2px 0px rgba(0,0,0,0.12)',
    boxShadow: '0px 1px 3px 2px rgba(0,0,0,0.2)',
  },
  handleContent: {
    marginTop: theme.spacing(1),
  },
  peopleCount: {
    fontWeight: 'bold',
  },
  total: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
  icon: {
    fontSize: '32px',
  },
}));

export default function ReceiptLowerHandle({ noBoxShadow }) {
  const classes = useStyles();

  return (
    <Box pt={2} pb={4} px={8} className={(noBoxShadow ? '' : `${classes.handleWrapperBoxShadow}`) + ` ${classes.handleWrapper} ${classes.noPointer}`}>
      <DrawerLine />
      <Grid container className={classes.handleContent}>
        <Grid item xs={2}>
          <PeopleIcon className={classes.icon} />
        </Grid>
        <Grid item xs={2}>
          <UsersConsumer>
            {({ users }) => (
              <Typography variant="h5" className={classes.peopleCount}>{users.length}</Typography>
            )}
          </UsersConsumer>
        </Grid>
        <Grid item xs={8}>
          <TotalConsumer>
            {({ total }) => (
              <Typography variant="h5" className={classes.total}>${total}</Typography>
            )}
          </TotalConsumer>
        </Grid>
      </Grid>
    </Box>
  )
}
