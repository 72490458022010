import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardHeader, CardContent, CardActions,
  Grid, Box, TextField, Button, IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  addItemCard: {
    borderRadius: '16px',
    transition: '0.3s ease',
    transformOrigin: 'right bottom',
  },
  shrinkCard: {
    transform: 'scale(0.2)',
  },
  addItemHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  addItemClose: {
    color: theme.palette.primary.contrastText,
  },
}));

export default function ({ onClose, onSave }) {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    item: '',
    price: '',
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const [cardAnim, setCardAnim] = React.useState(false);

  React.useEffect(() => {
    setTimeout(function() {
      setCardAnim(true);
    }, 1)
  }, []);

  function handleSave() {
    onSave(values);
    handleClose();
  }

  function handleClose() {
    setCardAnim(false);
    setTimeout(function() {
      onClose();
    }, 250);
  }

  return (
    <Card className={`${classes.addItemCard} ` + (cardAnim ? '' : classes.shrinkCard)}>
      <CardHeader
        title="Add Item"
        action={
          <IconButton aria-label="close" onClick={() => handleClose()} className={classes.addItemClose}>
            <CloseIcon />
          </IconButton>
        }
        className={classes.addItemHeader}
      />
      <CardContent>
        <Grid container>
          <Grid item xs={9}>
            <Box pr={2}>
              <TextField
                id="add-item-name"
                label="Item"
                name="item-name"
                value={values.item}
                onChange={handleChange('item')}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box pl={2}>
              <TextField
                id="add-item-price"
                label="Price"
                name="item-price"
                type="number"
                value={values.price}
                onChange={handleChange('price')}
                fullWidth
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button color="primary" fullWidth variant="contained" onClick={() => handleSave()}>
          Save
        </Button>
        <Button color="primary" fullWidth onClick={() => handleClose()}>
          Cancel
        </Button>
      </CardActions>
    </Card>
  )
}
