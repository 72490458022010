import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, SwipeableDrawer } from '@material-ui/core';

import { ReceiptProvider, ReceiptConsumer } from './ReceiptProvider';
import PageAddItemsToUsers from './PageAddItemsToUsers';
import ReceiptUpperDrawer from './ReceiptUpperDrawer';
import ReceiptUpperHandle from './ReceiptUpperHandle';
import ReceiptLowerDrawer from './ReceiptLowerDrawer';
import ReceiptLowerHandle from './ReceiptLowerHandle';
import ReceiptItems from './ReceiptItems';

const useStyles = makeStyles(theme => ({
  receiptContainer: {
    // backgroundColor: '#edf1f7',
    height: '100%',
  },
  drawerContainer: {
    // '& > div:nth-of-type(3)': {
    //   bottom: '64px',
    //   height: '60px !important',
    // },
  },
  upperSwipeArea: {
    '& > .MuiDrawer-paper': {
      backgroundColor: '#edf1f7',
      borderRadius: '0 0 20px 20px',
    },
  },
  lowerSwipeArea: {
    '& > .MuiDrawer-paper': {
      backgroundColor: '#edf1f7',
      borderRadius: '20px 20px 0 0',
    },
  },
}));

export default function Receipt() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    upper: false,
    lower: false,
  });

  const toggleDrawer = (tab, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [tab]: open });
  };

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <PageAddItemsToUsers />
  )
}

{/* <Grid container justify="center" className={classes.receiptContainer}>
  <Grid item xs={12} md={6} lg={4} className={classes.drawerContainer}>
    <ReceiptConsumer>
      {({ data }) => (
        <SwipeableDrawer
          anchor="top"
          disableDiscovery={iOS}
          open={state.upper}
          onClose={toggleDrawer('upper', false)}
          onOpen={toggleDrawer('upper', true)}
          className={classes.upperSwipeArea}
          swipeAreaWidth={60}
          SwipeAreaProps={{
            children: <ReceiptUpperHandle name={data.name} />,
          }}
        >
          <ReceiptUpperDrawer data={data} />
        </SwipeableDrawer>
      )}
    </ReceiptConsumer>
    <ReceiptItems />
    <SwipeableDrawer
      anchor="bottom"
      disableDiscovery={iOS}
      open={state.lower}
      onClose={toggleDrawer('lower', false)}
      onOpen={toggleDrawer('lower', true)}
      className={classes.lowerSwipeArea}
      swipeAreaWidth={60}
      SwipeAreaProps={{
        children: <ReceiptLowerHandle total={'$100'} />,
      }}
    >
      <ReceiptLowerDrawer />
    </SwipeableDrawer>
  </Grid>
</Grid> */}
