import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles({
  nav: {
    bottom: 0,
    position: 'fixed',
    width: '100%',
  },
});

export default function Nav() {
  const classes = useStyles();
  const [value, setValue] = React.useState('home');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BottomNavigation value={value} onChange={handleChange} className={classes.nav}>
      <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} />
      <BottomNavigationAction label="Receipt" value="receipt" icon={<ReceiptIcon />} />
      <BottomNavigationAction label="List" value="list" icon={<ListIcon />} />
      <BottomNavigationAction label="Account" value="account" icon={<PersonIcon />} />
    </BottomNavigation>
  )
}
