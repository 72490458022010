import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, IconButton, Button } from '@material-ui/core';
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend';
// import Backend from 'react-dnd-touch-backend';

import Navigation from '../Navigation';
import ReceiptItems from './ReceiptItems';
import ReceiptUsers from './ReceiptUsers';

const useStyles = makeStyles(theme => ({
  itemsWrapper: {
    flexGrow: 1,
  },
}));

export default function PageAddItemsToUsers() {
  const classes = useStyles();

  return (
    <Navigation back="/image" next="/taxtip">
      <DndProvider backend={Backend}>
        <Box mx={8} className={classes.itemsWrapper}>
          <ReceiptItems />
        </Box>
        <ReceiptUsers />
      </DndProvider>
    </Navigation>
  )
}
